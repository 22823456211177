import type { SiteSiteMetadata } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'

export const useCmsLpOfertasQuery = () => {
  const data = useStaticQuery(
    graphql`
      query LandingPageOfertasQuery {
        site {
          siteMetadata {
            titleTemplate
            title
            description
            siteUrl
          }
        }
        allCmsInstitutionalPage(
          filter: {
            seo: { siteMetadataWithSlug: { slug: { eq: "/ofertas" } } }
          }
        ) {
          edges {
            node {
              seo {
                siteMetadataWithSlug {
                  slug
                }
              }
              name
              sections {
                data
              }
            }
          }
        }
      }
    `
  )

  const site: SiteSiteMetadata = data?.site.siteMetadata

  const bannerImages: BannerImagesData[] =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[0]?.data?.allItems

  const collectionShelfOne: TShelf =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[1]?.data

  const collectionShelfTwo: TShelf =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[2]?.data

  const collectionShelfThree: TShelf =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[3]?.data

  const collectionShelfFour: TShelf =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[4]?.data

  return {
    data,
    site,
    bannerImages,
    collectionShelfOne,
    collectionShelfTwo,
    collectionShelfThree,
    collectionShelfFour,
  }
}
